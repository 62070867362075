.help-page{
    width: 100%;
    height: 100vh;
    background-color:rgba(34, 34, 34, 0.9);
    color: rgb(241, 100, 30);
    padding: 10px;
}

.help-page > * {
    width:80%;
    margin-left:10%;
    margin-right:10% ;
}
