.cart-card{
    display: flex;
    width: 40vw;
    border-top: 1px solid rgba(128, 128, 128, 0.3);
    max-height: 400px;
    margin-bottom: 5vh;
}

#item-name{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    margin-bottom: 10px;
    height:1fr;

}

.item-details > * {
    margin: 10px;
    box-sizing: border-box;
}

#delete-button{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 20px;
    height:30px;
    width:80px;
    font-size: 15px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    justify-self:end;
    background-color: rgb(241, 100, 30);
}

#delete-button:hover{
    background-color: rgb(241, 100, 30, .5);
}

.select-quant{
    width:60px;
    height: 40px;
    text-align: center;
    box-shadow: 0px 1px 6px 0px rgb(34, 34, 34, 0.5);
    border: 1px solid white;
    border-radius: 6px;
    cursor: pointer;

}

.item-tools{
    display: flex;
    flex-direction: column ;
    justify-content:space-evenly;
    align-items: center;
}

.item-details{
    width:40%;
    box-sizing: border-box;
    /* display: flex;
    flex-direction: column; */

}

img {
    object-fit:contain;
    height: 100%;
}

.descrip{
    height:40%;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

.card-right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    margin-top: 10px;
    width: 100%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.card-image-container{
    height: 100%;
    width: 100%;
}

.quant{
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.quant > *{
    margin-left: 10px;
}
