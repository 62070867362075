.contact-container{
    height:200px;
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items:baseline;
    justify-content: space-evenly;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color:palegoldenrod;
    font-size: 15px;
    border-radius: 15px;
}

.contact-info{
    font-size: 18px;
    padding: 10px;
}

.contact-info > a{
    color:green;
}
