.cart-head{
    font-size: 32px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

}

.cart-page{
    display:flex;
    flex-direction: column;
    align-items: center;
    color: ivory;
    min-height: 100vh;
}

.cart-page > *  {
    margin-top: 10px;
}

#checkout-button{
    background-color: rgb(241, 100, 30);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 20px;
    margin: 20px;
    width:20em;
    height: 3em;
    cursor: pointer;
    transition: scale 0.1s;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#checkout-button:hover{
    background-color: rgba(241, 100, 30, .5);

}
