#footer-container{
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: rgb(241, 100, 30, .9);
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}

#footer-container > * {

    font-size: 15px;

}

.help-button{
  cursor: pointer;
}

#footer{
  font-size: 15px;
}
