.card-image{
    cursor: pointer;
    width: 100%;
    height: 100%;
    /* border-radius: 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .card-image:hover{
    box-shadow: 0px 0px 10px #ed0202;
    cursor: pointer;
} */

.delete-button{
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
}

.card-header{
    display: flex;
    justify-content: center;
    margin-top: auto;
}

.prod-head{
    padding:10px;
}

.owner-buttons{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    background-color: black;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 5px;
    box-sizing: border-box;
}

.owner-buttons > *{
    font-size: 20px;
    background-color: rgb(241, 100, 30);
    color: white;
    border-radius: 15px;
    padding: 2px;
    width:70px;
    text-align: center;
}

.owner-buttons > *:hover{
    background-color: rgba(241, 100, 30, 0.5);
}

.card{
    color:ivory;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    /* border-radius: 40px; */
    object-fit: contain;
    display: flex;
    flex-direction: column;
}

.card-image > img{
    /* height: auto;
    width: 100%;
    max-width: 100%; */
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;

}

