.card-gallery {
   display:grid;
   grid-template-columns: repeat(5, 1fr);
   max-width: 1600px;
   min-width: 320px;
   width: 100%;
   height: auto;
   margin-top: 0.5em;
   margin-bottom: 5em;;
}

.card-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height:100%;
    width: 100%;
    max-width: 300px;
    box-sizing: border-box;
    background-color: rgba(241, 100, 30);
    /* box-shadow: 0px 0px 01px #69696946; */
    border-radius: 15px;
    /* max-width: 300px;
    max-height: 1fr; */
    margin: 10px;
    box-sizing: border-box;
    max-height: 400px;
}

.card-container:hover{
    box-shadow: 0px 0px 10px #696969;
    cursor: pointer;
}

.card-outer{
    height: auto;
    max-width:100%;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 10px;
}

#page{
    display: flex;
    justify-content: center;
    min-height: 100vh;
}

#root{
    background-color: rgba(0,0,0, 0.8);

}

#mypage{
    display: flex;
    justify-content: center;

}

.filter{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 50px;
    margin-bottom: 10px;


}

.category-button{
    cursor: pointer;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: ivory;
    height:100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(241, 100, 30);
}

.category-button:last-of-type{
    border-right:0px;
}

.category-button:hover{
    background-color: rgba(241, 100, 30, 0.5);
    
}

.filter > span.current{
    color: rgba(241, 100, 30);
    background-color: rgba(255, 255, 240, 0.846);
}

.card-outer                                                                                                                                                                                  > .owned{
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
