.product-form {
    display: flex;
    flex-direction: column;
    width:30vw;
    height: auto;
    align-items:center;
    justify-content: baseline;
    border: solid 0px rgba(0, 0, 0, .5);
    border-radius: 20px;
    background-color: rgba(34, 34, 34, 0.8);
}

h2{
    color: rgb(241, 100, 30);
}

.product-form > label > input {
    width: 300px;
    height: 90%;
    border-radius: 5px;
    float: right;
    width: 70%;
}

.product-form > button {

    border-radius: 10px;
    border: none;
    background: rgb(241, 100, 30);
    color: ivory;
    margin-top: auto;
    height: 50px;
    cursor: pointer;
    width:300px;
    margin-bottom: 10px;
}

.product-form > button:hover {
    background-color: ivory;
    color: rgb(241, 100, 30);
}

.product-form > label {
    height: 50px;
    border-radius: 10px;
    margin: 10px;
    width: 450px;
    font-size: 20px;
    text-align: start;
    display: block;
    color:#E2DFD2;
}

.modal-content{
    border-radius: 10px;
}

.product-form > label > .checkCat{
    width: 10px;
    height: 10px;

}

.checkbox-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 5px #696969;
    padding: 18px;
}

.checkbox-container > label{
    padding: 10px;
    color: ivory;
}

.cat-tools {
    display: flex;
    flex-direction: row;
    height:30px;
    justify-content: center;
    margin:10px;


}

.cat-container {
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 450px;
    height: stretch;
    /* border: 1px solid rgb(220,220,220); */
    box-shadow: 0px 0px 5px #696969;
    margin-bottom: 20px;
    border-radius: 10px;

}

.cat-name{
   background-color: rgb(241, 100, 30);
   color: #E2DFD2;
   border: 1px solid black;
   border-radius: 10px;
   padding: 0px 5px 3px 5px;
   font-size: 20px;
   height: 30px;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#addCat{
    border-radius: 7px;
    border: none;
    background: ivory;
    color: black;
    cursor: pointer;
    padding:4px;
    margin: 5px;
    text-align: center;

}

.remove-cat {
    cursor: pointer;
}

#addCatForm{
    display: flex;
    flex-direction: row;
    /* margin-top: 10px; */
    align-items: center;
    margin-bottom: 20px;
}

#procat-header{
    color: #E2DFD2;
}

#addCatForm > select {
    height: 65%;
}

#cat-head{
    color:rgb(241, 100, 30);
    margin-top: 30px;
}

label.cat-label{
    background-color: rgb(241, 100, 30);
    margin: 7px;
    padding: 10px;
    border-radius: 20px;
}

input.checkCat{
    cursor: pointer;
}

label.chosen {
    background-color: ivory;
    color: rgb(241, 100, 30);
}

#cat-ins{
    color: ivory;
}
