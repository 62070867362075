.review{
    font-size: 18px;
    font-family: Arial, sans-serif;
    margin: 5px;
    box-sizing: border-box;
}

.user-tools {
    display: flex;
}

.user-tools > * {
    font-size: 12px;
    width:auto;
    margin:5px;
    margin-top:  0px;
    border-radius: 8px;
    padding: 3px;
    cursor: pointer;
    background-color: rgb(241, 100, 30);
}

#editReview:hover{
    background-color: rgba(241, 100, 30, 0.5);
}

.deleteReview:hover{
    background-color: rgba(241, 100, 30, 0.5);
}

.username {
    margin-left: 5px;
    font-size: 13px;
    text-decoration: underline;
    color: gray;

}

.date{
    margin-left: 10px;
    font-size: 13px;
    color: gray;
}

.post-info{
    display: flex;
}

.reviewCard{
    margin-bottom: 50px;
    color: ivory;
}
