.login {
    display: flex;
    flex-direction: column;
    width:320px;
    height: 300px;
    align-items: center;
    box-sizing: border-box;
}



.login > label > input {
    width: 300px;
    height: 90%;
    border-radius: 5px;

}

.login > button {

    border-radius: 10px;
    border: none;
    background: rgb(34, 34, 34);
    color: white;
    margin-top: 50px;
    height: 50px;
    cursor: pointer;
    width:100%;
    margin-bottom: 10px;
}

.login > label {
    height: 50px;
    border-radius: 10px;
    margin: 10px;
    width: auto;
}

#signin-head{
    text-align: center;
    font-size: 30px;
}

.err {
    color:red
}
