.profile-dropdown {
  position: absolute;
  top: 100px;
  padding-inline-start: 0px;
  width:200px;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #fff;
}

.hidden {
  display: none;
}

.nav-container{
  border-bottom: 2px solid rgba(128, 128, 128, 0.35);
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url("https://thumbs.gfycat.com/ThoroughUnnaturalAlbacoretuna-size_restricted.gif") ;

  background-color: white;
  background-size: contain;
}

#home-button{
  color: rgb(241, 100, 30);
  font-size: 40px;
  font-family: 'Lora', serif;
  font-weight: bold;
  cursor: pointer;


}

.menu{
  cursor: pointer;
}

.nav-left{
  display: flex;
  justify-content:space-evenly;
  padding-left: 8vw;
}

.nav-right{
  display: flex;
  justify-content: space-between;
  padding-right: 2vw;

}

.nav-right > * {
  margin: 20px;
}

.image-container{
  max-width: 100%;
  max-height: 100%;
}

.prints{
  width: auto;
  height: 100px;

}

.head{
  color: rgb(241, 100, 30);
}

.head:hover{
  transform: scale(1.1);

}

.profile-dropdown > li{
  list-style:none;
  display: block;
  width:100%;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 10px;
}

.button{
  cursor: pointer;
}

.modal-button{
  display: block;
  width:100%;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  /* padding: 10px; */
}

.modal-button:hover{
  cursor: pointer;
}

.demo{
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}

.badge:after{
  content:attr(value);
  font-size:12px;
  color: #fff;
  background: red;
  border-radius:50%;
  padding: 0 5px;
  position:relative;
  left:-8px;
  top:-10px;
  opacity:0.9;
}
