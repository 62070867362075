.product-page{
    width:60%;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 30px;
    display: flex;
    color: ivory;

}

.page-container{
    min-height: 100vh;
}

.column-left{
    width: 60%;
}

.column-right{
    width: 30%;
}

#image{
    width:70%;
    height: auto;
}

#price{
    margin-top: 3vw;
    font-size: 30px;
    /* font-weight: bold; */
    font-family: Arial, Helvetica, sans-serif;
}

#desc{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    margin-bottom: 5vw;
}

.column-right > *{
    padding:10px;
}

.bu{
    width:100%;
    font-size: 20px;
    text-align: center;
    margin: 10px;
    border-radius: 30px;
    cursor: pointer;
    color: ivory;
    background-color: rgb(241, 100, 30);
}

.bu:hover{
    background-color: rgba(241, 100, 30, 0.5);
}

.review-header {
    font-size: 30px;
    font-family: Garamond, Georgia, serif;
    margin: 5px;
}

.reviews-container {
    margin-left: 20%;
    margin-right: 50%;
    color: ivory;
}

#review-button{
    font-size: 15px;
    width: auto;
    text-align: center;
    border-radius: 30px;
    cursor: pointer;
    margin-left: auto;
    padding: 5px;
    margin-right: 15%;
    color: ivory;
    background-color: rgb(241, 100, 30);
}

#review-button:hover{
    background-color: rgba(241, 100, 30, 0.5);
}

.revForm {
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 350px;
    height: auto;
    align-items: center;
}

textarea {
    resize: none;
    width:100%;
    height:80px;
    overflow-y: scroll;
}

.revForm > h4 {
    text-align: center;
    margin:0;
}

.revForm > * {
    margin: 5px;
}

.review-info {
    display: flex;
    align-items: flex-end;
    margin-left: 10px;
}

.avg-rating {
    margin: 5px;
    margin-bottom: 4px;
}

.review-top {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(128, 128, 128, 0.5);
    margin-bottom: 30px;
    padding-bottom: 30px;
}
